import { compact, difference, values } from 'lodash';

import { ApplicationApi } from 'redux/modules/App/api';
import { editOrder } from 'redux/modules/App/actions';
import { getProduct } from 'redux/modules/Bundles/api';
import {
  productMap,
  products as allProducts,
  PRODUCT_MOBILE_2,
  PRODUCT_MOBILE,
} from 'redux/modules/Bundles/constants';
import CashbackApi from 'redux/modules/Cashback/api';
import SummarySection from 'app/components/modules/OrderSummary/SummarySection';
import { BroadbandApi } from 'redux/modules/Broadband/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { connector } from 'redux/utils/connector';
import { getQuotePrice } from 'redux/modules/BillIncomeProtector/api';
import { PRODUCT_ID_BILL_PROTECTOR } from 'redux/modules/BillIncomeProtector/constants';
import { isEligibleForFreeEnergy } from 'app/redux/modules/Bundles/api';

import { getSummaryRequest } from '../actions';
import { OrderSummaryApi } from '../api';
import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
  addOns as allAddOns,
  defaultBillProtectorPrice,
  defaultCashbackCardPrice,
  STORE_NAME,
} from '../constants';
import {
  transformAddOns,
  transformProducts,
  getProductTotals,
} from '../presentation';

const getAddOnPrice = (state, id) => {
  switch (id) {
    case ADD_ON_CASHBACK_CARD:
      return CashbackApi.getMonthlyFee(state) || defaultCashbackCardPrice;
    case ADD_ON_BILL_PROTECTOR:
      return (
        getQuotePrice(state, PRODUCT_ID_BILL_PROTECTOR) ||
        defaultBillProtectorPrice
      );
  }
};

const getOtherServiceTitle = (id) =>
  id === PRODUCT_MOBILE_2 ? 'Mobile additional SIM' : productMap[id];

const getOtherServices = (products, state) => {
  const selectedProductIds = compact(values(products)).map(({ id }) => id);

  // 2nd SIM is actually part of the mobile product
  // in order to display it as a separate tile, we hardcode it on the FE
  if (products[PRODUCT_MOBILE]?.sims.length > 1) {
    selectedProductIds.push(PRODUCT_MOBILE_2);
  }

  const unselectedProductIds = difference(allProducts, selectedProductIds);

  return unselectedProductIds.map((id) => ({
    id,
    title: getOtherServiceTitle(id),
    price: getProduct(state, id).price,
    available: getProduct(state, id).available,
    lockedSavings: OrderSummaryApi.getSummaryBenefits(
      state,
      selectedProductIds,
      id
    ),
  }));
};

const getOtherAddOns = (addOns, products, state) => {
  const selectedAddOnIds = compact(values(addOns)).map(({ id }) => id);
  const possibleAddOns = allAddOns.filter((id) => {
    if (products?.insurance?.incomeProtector) {
      return id !== ADD_ON_BILL_PROTECTOR;
    }

    return true;
  });
  const unselectedAddOnIds = difference(possibleAddOns, selectedAddOnIds);
  return unselectedAddOnIds.map((id) => ({
    id,
    price: getAddOnPrice(state, id),
  }));
};

const filterSecondMobile = ({ id }) => id !== PRODUCT_MOBILE_2;

const handleMobileTilesDisplay = (products, otherServices) => {
  const isMobileSelected = !!products[PRODUCT_MOBILE];
  const isSecondMobileSelected = products[PRODUCT_MOBILE]?.sims.length > 1;

  // Show 2nd SIM tile only if single SIM mobile is selected
  if (!isMobileSelected || (isMobileSelected && isSecondMobileSelected)) {
    return {
      products,
      otherServices: otherServices.filter(filterSecondMobile),
    };
  }

  return {
    products,
    otherServices,
  };
};

// 2nd SIM is actually part of the Mobile product
// in order to display it as a separate tile, we hardcode it on the FE
const handleAddService = (id) =>
  editOrder(id === PRODUCT_MOBILE_2 ? PRODUCT_MOBILE : id, true);

export default connector(
  STORE_NAME,
  (state) => {
    const application = OrderSummaryApi.getApplication(state);

    const { breakdown } = application;

    const productsRaw = transformProducts(application);
    const otherServicesRaw = getOtherServices(productsRaw, state);
    const { products, otherServices } = handleMobileTilesDisplay(
      productsRaw,
      otherServicesRaw
    );

    const addOns = transformAddOns(application.products, state);
    const otherAddOns = getOtherAddOns(addOns, products, state);
    const serviceCount = ServiceSelectionApi.getSelectedServices(state).length;

    const hasFFBroadband = BroadbandApi.isFTTPSelected(state);

    const { totalPrice, discountedTotalPrice } = getProductTotals(
      products,
      addOns
    );

    const discountedPrice =
      discountedTotalPrice.value === totalPrice.value
        ? undefined
        : discountedTotalPrice;

    const shouldShowFreeEnergyOffer = isEligibleForFreeEnergy(state);

    return {
      products,
      otherServices,
      addOns,
      otherAddOns,
      hasFFBroadband,
      totalPrice,
      serviceCount,
      discountedTotalPrice: discountedPrice,
      isApplicationReadOnly: !!ApplicationApi.getIsReadOnly(state),
      breakdown,
      isPartner: !ApplicationApi.isOrganicApplication(state),
      isSales: ApplicationApi.isSalesApplication(state),
      hasEvInterest: application.evTariffInterest,
      shouldShowFreeEnergyOffer,
    };
  },
  {
    getSummaryRequest: (isApplicationComplete) =>
      getSummaryRequest(false, isApplicationComplete),
    editService: editOrder,
    addService: handleAddService,
  }
)(SummarySection);
