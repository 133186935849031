import { compact, get, values } from 'lodash';

import { sumPrices } from 'app/redux/utils/priceUtils';
import { formatPrice } from 'app/redux/utils/formatter';
import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
} from 'redux/modules/Bundles/constants';

import transformBroadband from './broadband';
import transformMobiles from './mobile';
import transformInsurance from './insurance';
import transformEnergy from './energy';
import transformCashback from './cashback';
import transformBillProtector from './billProtector';
import { priceOpts } from '../constants';

function getBreakdown(breakdown, product) {
  return breakdown?.services?.find((p) => p.name === product);
}

export const transformProducts = ({ products, quotes, breakdown }) => {
  const homephone = get(products, 'homephone', null);
  const broadband = get(products, 'broadband', null);
  const mobiles = get(products, 'mobiles', null);
  const electricity = get(products, 'electricity', null);
  const gas = get(products, 'gas', null);
  const incomeProtector = get(products, 'incomeProtector', null);
  const boiler = get(products, 'boilerInsurance', null);

  const breakdownBroadband = getBreakdown(breakdown, 'broadband');
  const breakdownHomephone = getBreakdown(breakdown, 'homephone');

  return {
    [PRODUCT_BROADBAND]: transformBroadband(
      broadband,
      homephone,
      breakdownBroadband,
      breakdownHomephone
    ),
    [PRODUCT_MOBILE]: mobiles ? transformMobiles(mobiles) : undefined,
    [PRODUCT_ENERGY]: transformEnergy({ electricity, gas }, quotes),
    [PRODUCT_INSURANCE]: transformInsurance({ incomeProtector, boiler }),
  };
};

export const transformAddOns = (products, state) => {
  const billProtector = get(products, 'billProtector', null);

  return {
    cashback: transformCashback(state),
    billProtector: billProtector
      ? transformBillProtector(billProtector)
      : undefined,
  };
};

export function formatDeposit(totals) {
  const { deposit } = totals;
  return deposit ? formatPrice({ value: deposit, ...priceOpts }) : null;
}

export function getRawTotals(transformedProducts, transformedAddons, deposits) {
  let productTotals = {};

  Object.entries(transformedProducts).forEach(([name, product]) => {
    if (product) {
      productTotals[name] = product.discountedPrice
        ? product.discountedPrice.value
        : product.price.value;
    }
  });

  Object.entries(transformedAddons).forEach(([name, product]) => {
    if (product) {
      productTotals[name] = product.price.value;
    }
  });

  const { totalPrice, discountedTotalPrice } = getProductTotals(
    transformedProducts,
    transformedAddons
  );

  return {
    deposit:
      deposits && deposits.total && deposits.total.value !== 0
        ? deposits.total.value
        : null,
    monthly: totalPrice.value,
    promo:
      discountedTotalPrice.value === totalPrice.value
        ? null
        : discountedTotalPrice.value,
    productTotals,
  };
}

export const getProductTotals = (products, addOns) => {
  const totalPrice = sumPrices([
    ...compact(values(products)).map(({ price }) => price),
    ...compact(values(addOns)).map(({ price }) => price),
  ]);

  const discountedTotalPrice = sumPrices([
    ...compact(values(products)).map(
      ({ price, discountedPrice }) => discountedPrice || price
    ),
    ...compact(values(addOns)).map(({ price }) => price),
  ]);
  return { totalPrice, discountedTotalPrice };
};

export const composePartner = (id, name) => [
  {
    title: 'Referrer Name',
    details: name,
    colorStyle: 'grey',
    testId: 'PartnerName',
  },
  {
    title: 'Referrer ID',
    details: id,
    colorStyle: 'grey',
    testId: 'PartnerID',
  },
];
